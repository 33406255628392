import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

function FeaturedPost(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href="#" style={{ height: "100%" }}>
        <Card sx={{ display: "flex", height: "100%" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {post?.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {post?.date}
            </Typography>
            <Typography variant="subtitle1" paragraph style={{marginTop: 10}}>
              {post?.description}
            </Typography>
            <a href={post?.link} style={{ textDecoration: "none" }}>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ textDecoration: "none" }}
              >
                Voir plus
              </Typography>
            </a>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPost;
